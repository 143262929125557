import styled from 'styled-components';

import { SCREEN } from 'lib/constants';
import { COLORS } from 'lib/theme';

export default styled.div`
  background-color: ${COLORS.dark};
  height: ${SCREEN.height}px;
  width: ${SCREEN.width}px;
  overflow: hidden;
  position: relative;
`;
