import React from 'react';

import { PieChart } from 'react-minimal-pie-chart';

import { COLORS } from 'lib/theme';

export default function GaugeChart({ data, options }) {
  return (
    <PieChart
      // animate
      data={data}
      startAngle={135}
      endAngle={0}
      lengthAngle={270}
      lineWidth={25}
      radius={45}
      background={COLORS.gray}
      {...options}>
      <text
        x={0}
        y="80%"
        textAnchor="middle"
        fill={COLORS.light}
        style={{ fontSize: 7 }}>
        여유
      </text>
      <text
        x="100%"
        y="78%"
        textAnchor="middle"
        fill={COLORS.light}
        style={{ fontSize: 6 }}>
        <tspan>매우</tspan>
        <tspan x="100%" dy={8}>
          혼잡
        </tspan>
      </text>
    </PieChart>
  );
}
