import { CONGESTION_LEVELS } from 'lib/constants';

export const getCongestionLevel = (count, area) => {
  if (count === 0 || count <= area / CONGESTION_LEVELS.sparse.distance)
    return CONGESTION_LEVELS.sparse;
  if (count <= area / CONGESTION_LEVELS.normal.distance)
    return CONGESTION_LEVELS.normal;
  if (count <= area / CONGESTION_LEVELS.crowded.distance)
    return CONGESTION_LEVELS.crowded;

  return CONGESTION_LEVELS.congested; // CONGESTION_LEVELS.congested.distance
};

export const getRandomPosNegNumber = (max) =>
  Math.random() * max * (Math.random() - 0.5) * 2;
