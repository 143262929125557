import React from 'react';

import Wrapper from 'components/Wrapper';
import KioskMap from 'pages/KioskMap';

export default function App() {
  return (
    <Wrapper>
      <KioskMap />
    </Wrapper>
  );
}
