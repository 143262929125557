export const CONGESTION_LEVELS = {
  sparse: {
    label: '쾌적',
    name: 'sparse',
    fill: '#36B1E4',
    distance: 5, // social distance per person in square meters
  },
  normal: {
    label: '적정',
    name: 'normal',
    fill: '#00EA00',
    distance: 4, // social distance per person in square meters
  },
  crowded: {
    label: '다소 혼잡',
    name: 'crowded',
    fill: 'gold',
    distance: 3, // social distance per person in square meters
  },
  congested: {
    label: '매우 혼잡',
    name: 'congested',
    fill: 'red',
    distance: 2, // social distance per person in square meters
  },
};

export const SCREEN = {
  height: process.env.REACT_APP_SCREEN_HEIGHT,
  width: process.env.REACT_APP_SCREEN_WIDTH,
};
