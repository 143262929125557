import React, { useMemo } from 'react';

import Container from 'components/Container';
import GaugeChart from 'components/GaugeChart';
import InformationBoard from 'components/InformationBoard';
import SensorsMap from 'components/SensorsMap';

import { useQueries } from 'react-query';

import { fetchRealtimeVisitorCount } from 'lib/api';
import { sensors } from 'lib/config';
import { getCongestionLevel } from 'lib/utils';

import styled from 'styled-components';

import { CONGESTION_LEVELS } from 'lib/constants';
import { COLORS } from 'lib/theme';

const ChartsContainer = styled.div`
  position: absolute;
  top: 0;
  right: 200px;
  bottom: 0;
  left: 500px;

  & div.chart {
    border: 1px ${COLORS.light} solid;
    border-radius: 5px;
    height: 200px;
    margin: 20px 50px;
  }

  & div.name {
    color: ${COLORS.light};
    font-size: 16px;
    font-weight: bold;
    margin-top: 5px;
    position: relative;
    text-align: center;
    & small {
      position: absolute;
      right: 5px;
      font-size: 12px;
      font-weight: normal;
    }
  }
`;

export default function KioskMap() {
  const SENSORS = useMemo(
    () => Object.values(sensors).sort((a, b) => a.index - b.index),
    []
  );

  const results = useQueries(
    SENSORS.map((sensor) => ({
      queryKey: ['sensor', sensor.id],
      queryFn: fetchRealtimeVisitorCount,
      refetchInterval: 60000,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: false,
    }))
  );

  const resultData = useMemo(
    () =>
      results.map((result, i) => {
        const sensor = SENSORS[i];
        const data = result?.data?.data || {};
        const count = data?.count || 0;
        const max = sensor.area / CONGESTION_LEVELS.congested.distance;
        const level = getCongestionLevel(count, sensor.area);

        return {
          id: sensor.id,
          name: sensor.name,
          count,
          level,
          max,
        };
      }),
    [results]
  );

  return (
    <Container>
      <SensorsMap sensors={SENSORS} data={resultData} />
      <ChartsContainer>
        {resultData.map((data, i) => {
          const sensor = SENSORS[i];
          const { count, level, max } = data;
          return (
            <div key={sensor.id} className="chart">
              <div className="name">
                {sensor.name}
                <small>
                  {sensor.area} m<sup>2</sup>
                </small>
              </div>
              <div style={{ height: 180 }}>
                <GaugeChart
                  data={
                    count
                      ? [
                          {
                            title: sensor.name,
                            value: count > max ? max : count,
                            color: level.fill,
                          },
                        ]
                      : [
                          {
                            title: sensor.name,
                            value: 0,
                          },
                        ]
                  }
                  options={{
                    label: () => level.label,
                    // label: () => `${count}명`,
                    labelPosition: 0,
                    labelStyle: {
                      fontSize: '15px',
                      fontFamily: 'sans-serif',
                      fill: level.fill,
                      // fill: COLORS.light,
                    },
                    totalValue: max,
                  }}
                  label={{
                    fill: level.fill,
                    value: level.label,
                  }}
                />
              </div>
            </div>
          );
        })}
      </ChartsContainer>
      <InformationBoard />
    </Container>
  );
}
