import axios from 'axios';

const coordinates = process.env.REACT_APP_SITE_COORDINATES?.split(',');

export const fetchLastMinuteVisitor = ({ queryKey }) => {
  const url = 'api-master/Sensor/LastMinuteVisitor';
  const params = new URLSearchParams({
    user: process.env.REACT_APP_USERNAME,
    pass: process.env.REACT_APP_PASSWORD,
    sensorId: queryKey[1],
    limit: 1,
  });
  return fetch(`${url}?${params}`).then((res) => res.json());
};

export const fetchOpenWeatherApi = () => {
  const url = process.env.REACT_APP_OPEN_WEATHER_URL;
  const params = new URLSearchParams({
    appid: process.env.REACT_APP_OPEN_WEATHER_API_KEY,
    lat: coordinates?.[0],
    lon: coordinates?.[1],
    lang: 'kr',
    units: 'metric',
  });
  return fetch(`${url}?${params}`).then((res) => res.json());
};

export const fetchRealtimeVisitorCount = ({ queryKey }) => {
  const url = 'api-realtime/sap-api/realtime-visitor-count/';
  const sensorId = queryKey[1];

  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    auth: { username: 'adminrealtime', password: 'Vdm1n_R3@LT1me&728!' },
  };

  const params = new URLSearchParams({
    timezone: 'Asia/Seoul',
  });
  return axios.get(`${url}${sensorId}?${params}`, config);
};
